import 'bootstrap-icons/font/bootstrap-icons.css';
import parse from 'html-react-parser';
import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table, Pagination, Form } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import { deleteMessage, getUserMessages, getAssignedMessages, fetchCampaigns } from '../services/api'; // Corrected import
import { log } from '../services/logger';

const MessagesPage = () => {
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const shouldOpenModal = queryParams.get('new') === 'true';
  const [messages, setMessages] = useState([]); // State to store messages
  const { user } = useUserContext();
  const [userId, setUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const messagesPerPage = 10; // Messages per page
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  useEffect(() => {
    if (userId) {
      log('Fetching messages for userid: ', userId);
    }
    fetchMessages();
  }, [user, userId]); // Dependency array includes `user` to refetch messages when `user` changes


  const fetchMessages = () => {
    if (user) {
      const decodedToken = jwtDecode(user); // Assuming the JWT is stored in user.token
      //const userId = decodedToken.userId;
      setUserId(decodedToken.userId);
      getUserMessages(decodedToken.userId)
        .then(response => {
          setMessages(response.data);
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    }
  };

  // const handleDeleteMessage = (messageId) => {
  //   if (window.confirm("Are you sure you want to delete this message?")) {
  //     deleteMessage(messageId)
  //       .then(response => {
  //         log('Message deleted:', response.data);
  //         fetchMessages(); // Refresh the messages list
  //       })
  //       .catch(error => {
  //         console.error('Error deleting message:', error);
  //         // Optionally show an error message to the user
  //       });
  //   }
  // };

    // Filter messages based on search query
    const filteredMessages = messages.filter(
      (message) =>
        message.messageName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        message.messageContent.toLowerCase().includes(searchQuery.toLowerCase())
    );
  
  const handleDeleteMessage = async (messageId) => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      try {
        console.log("Deleting message:", messageId);
  
        // Step 1: Fetch assigned messages
        const assignedMessagesResponse = await getAssignedMessages(userId);
        console.log("Assigned Messages Response:", assignedMessagesResponse);
  
        const assignedMessages = assignedMessagesResponse.data;
        const assignedMessage = assignedMessages.find(
          (msg) => msg.messageId === messageId
        );
        console.log("Assigned Message Found:", assignedMessage);
  
        if (assignedMessage) {
          // Step 2: Fetch campaigns
          const campaignsResponse = await fetchCampaigns(userId);
          console.log("Campaigns Response:", campaignsResponse);
  
          const campaigns = campaignsResponse.data;
          const campaign = campaigns.find(
            (c) => c._id === assignedMessage.campaignId
          );
          console.log("Associated Campaign Found:", campaign);
  
          if (campaign && ['in-review', 'live', 'closed'].includes(campaign.statusV1)) {
            alert(
              "This message cannot be deleted because it is associated with a campaign that is in-review, live, or closed."
            );
            return; // Prevent deletion
          }
        }
  
        // Step 3: Proceed with deletion
        const deleteResponse = await deleteMessage(messageId);
        console.log("Message Deleted Response:", deleteResponse);
  
        fetchMessages(); // Refresh messages list
      } catch (error) {
        console.error("Error deleting message:", error);
        alert("An error occurred while attempting to delete the message.");
      }
    }
  };
  

  // Pagination logic
  const totalPages = Math.ceil(filteredMessages.length / messagesPerPage);
  const displayedMessages = filteredMessages.slice(
    (currentPage - 1) * messagesPerPage,
    currentPage * messagesPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <Row>
            <Col><h1>Messages</h1></Col>
            <Col>
              <div className="float-end">
                <Button variant="primary" as={Link} to="/dashboard/messages/create">
                  New Message
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 g-2"> {/* Use g-2 to reduce the gap */}
  <Col md="auto" className="d-flex align-items-center">
    <Form.Label className="mb-0">Search:</Form.Label>
  </Col>
  <Col md={3}>
    <Form.Control
      type="text"
      placeholder="Enter search query"
      size="sm"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
  </Col>
</Row>

          <Row>
            {displayedMessages.length === 0 ? (
              <div className="d-flex justify-content-center mt-3">
                <p>No messages found matching your search.</p>
              </div>
            ) : (
              <>
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Message Name</th>
                      <th>Message Content</th>
                      <th>Date Created</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {displayedMessages.map((message) => (
                      <tr key={message._id}>
                        <td>{message.messageName}</td>
                        <td>{message.messageContent.substring(0, 30)}...</td>
                        <td>{new Date(message.dateSubmitted).toLocaleDateString()}</td>
                        <td>{message.messageStatus}</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            as={Link}
                            to={`/dashboard/messages/edit/${message._id}`}
                          >
                            Edit
                          </Button>{' '}
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteMessage(message._id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Pagination>
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MessagesPage;
