import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Card, Row, Col, Alert } from 'react-bootstrap';
import {
  fetchCustomFields,
  fetchCampaigns,
  listProductTypesByUser,
  createCampaign,
  updateCampaign,
} from '../services/api';

const CreateQuickCampaign = ({ show, onHide, userId, editingCampaign }) => {
  const [campaignList, setCampaignList] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldValues, setCustomFieldValues] = useState({});
  const [formData, setFormData] = useState({
    parentCampaignId: '',
    bespokeProductTypeId: '',
    productType: '',
  });
  const [generatedCampaignName, setGeneratedCampaignName] = useState('');
  const [bespokeProducts, setBespokeProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fieldsResponse, campaignsResponse, bespokeProductsResponse] = await Promise.all([
          fetchCustomFields(),
          fetchCampaigns(userId),
          listProductTypesByUser(userId),
        ]);

        // Filter and sort fields for campaigns with `useForCreation` set to true
        const campaignFields = fieldsResponse.data
          .filter((field) => field.collectionName === 'Campaigns' && field.useForCreation)
          .sort((a, b) => a.orderId - b.orderId);

        setCustomFields(campaignFields);
        setCampaignList(campaignsResponse.data);
        setBespokeProducts(bespokeProductsResponse);
      } catch (err) {
        console.error('Error fetching data:', err);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    // Generate campaign name dynamically
    const name = customFields
      .map((field) => customFieldValues[field.label])
      .filter((value) => value) // Exclude empty values
      .join(' ');
    setGeneratedCampaignName(name);
  }, [customFields, customFieldValues]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'parentCampaignId' || name === 'bespokeProductTypeId' || name === 'productType') {
      setFormData((prev) => ({ ...prev, [name]: value }));
    } else {
      setCustomFieldValues((prevValues) => ({
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSave = async () => {
    try {
      const campaignData = {
        campaignName: generatedCampaignName,
        parentCampaign: formData.parentCampaignId,
        createdBy: userId,
        customFields: Object.entries(customFieldValues).map(([label, value]) => ({
          label,
          value,
        })),
      };

      if (editingCampaign) {
        await updateCampaign(editingCampaign._id, campaignData);
      } else {
        await createCampaign(campaignData);
      }

      onHide();
    } catch (err) {
      console.error('Error saving campaign:', err);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Quick Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-4">
          <Col className="text-center">
            <h5>Campaign Name:</h5>
            <h3>{generatedCampaignName || 'Name will be generated here based on your selections'}</h3>
          </Col>
        </Row>
        <Form>
          <Card className="mb-4">
            <Card.Header>
              <h5 style={{ fontWeight: 'bold', color: '#000000' }}>
                Campaign Name <span style={{ fontSize: '0.9em' }}>(Used for Name)</span>
              </h5>
            </Card.Header>
            <Card.Body>
              <Alert variant="info" className="mb-3">
                <strong>Note:</strong> The fields below will be used to generate the campaign name.
              </Alert>
              {customFields.length > 0 ? (
                customFields.map((field) => (
                  <Form.Group controlId={`customField-${field._id}`} key={field._id} className="mb-3">
                    <Form.Label>{field.label}</Form.Label>
                    {field.type === 'text' && (
                      <Form.Control
                        type="text"
                        name={field.label}
                        value={customFieldValues[field.label] || ''}
                        onChange={handleInputChange}
                      />
                    )}
                    {field.type === 'select' && (
                      <Form.Control
                        as="select"
                        name={field.label}
                        value={customFieldValues[field.label] || ''}
                        onChange={handleInputChange}
                      >
                        <option value="">Select an option</option>
                        {field.options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  </Form.Group>
                ))
              ) : (
                <p>No custom fields available.</p>
              )}
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Header>
              <h5>Parent Campaign</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group controlId="parentCampaignId" className="mb-3">
                <Form.Label>Do you want to link this campaign to another?</Form.Label>
                <Form.Select
                  name="parentCampaignId"
                  value={formData.parentCampaignId || ''}
                  onChange={handleInputChange}
                >
                  <option value="">Select Parent Campaign</option>
                  {campaignList
                    .filter((campaign) => !campaign.parentCampaign) // Exclude campaigns already linked
                    .map((campaign) => (
                      <option key={campaign._id} value={campaign._id}>
                        {campaign.campaignName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Header>
              <h5>Bespoke Product Type</h5>
            </Card.Header>
            <Card.Body>
              <Form.Group controlId="bespokeProductTypeId" className="mb-3">
                <Form.Label>Choose Your Product Type</Form.Label>
                <Form.Select
                  name="bespokeProductTypeId"
                  value={formData.bespokeProductTypeId || ''}
                  onChange={handleInputChange}
                >
                  <option value="">Select Bespoke Product Type</option>
                  {bespokeProducts.map((product) => (
                    <option key={product._id} value={product._id}>
                      {product.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Card>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Campaign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateQuickCampaign;
