import { Container, Row, Col, Form, Button, Card, Table, Alert } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { createCustomField, fetchCustomFields, updateCustomField, deleteCustomField, reorderCustomFields } from '../services/api';
import { useUserContext } from '../services/UserProvider'; // Assuming you have a context to provide user info
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const CustomFieldsPage = () => {
  const { user } = useUserContext();
  const [collectionName, setCollectionName] = useState('Campaigns');
  const [label, setLabel] = useState('');
  const [type, setType] = useState('text');
  const [options, setOptions] = useState([{ label: '', value: '' }]); // Array of option objects
  const [customFields, setCustomFields] = useState([]);
  const [editingFieldId, setEditingFieldId] = useState(null);
  const [editingField, setEditingField] = useState({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);


  // Load existing custom fields on page load
  useEffect(() => {
    if (user) {
      fetchCustomFields(user.userId)
        .then(response => {
          setCustomFields(response.data);
        })
        .catch(err => {
          console.error('Error fetching custom fields:', err);
        });
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const customFieldData = {
      label,
      type,
      collectionName,
      options: type === 'select' ? options : [], // Only pass options if type is 'select'
      required: false,
      useForCreation: collectionName === 'Campaigns' ? editingField.useForCreation || false : undefined,
    };
  
    await createCustomField(customFieldData)
      .then(() => {
        alert('Custom Field Created Successfully');
        setLabel('');
        setOptions([{ label: '', value: '' }]);
        fetchCustomFields(user.userId).then((response) => setCustomFields(response.data));
      })
      .catch((error) => {
        console.error('Error creating custom field:', error);
        alert('Error creating custom field. Please try again.');
      });
  };
  
  // Handle inline editing of a custom field
  const handleEdit = (fieldId) => {
    const field = customFields.find(f => f._id === fieldId);
    setEditingField(field);
    setEditingFieldId(fieldId);
  };

  const handleUpdate = async () => {
    await updateCustomField(editingFieldId, editingField)
      .then(() => {
        alert('Custom Field Updated Successfully');
        setEditingFieldId(null);
        setEditingField({});
        fetchCustomFields(user.userId).then(response => setCustomFields(response.data));
      })
      .catch(error => {
        console.error('Error updating custom field:', error);
        alert('Error updating custom field. Please try again.');
      });
  };

  const handleDelete = async (fieldId) => {
    if (window.confirm('Are you sure you want to delete this custom field?')) {
      await deleteCustomField(fieldId)
        .then(() => {
          alert('Custom Field Deleted Successfully');
          fetchCustomFields(user.userId).then(response => setCustomFields(response.data));
        })
        .catch(error => {
          console.error('Error deleting custom field:', error);
          alert('Error deleting custom field. Please try again.');
        });
    }
  };

  // Handle changes for option fields
  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setOptions(updatedOptions);
  };

  // Handle adding a new option row
  const handleAddOption = () => {
    setOptions([...options, { label: '', value: '' }]);
  };

  // Handle removing an option row
  const handleRemoveOption = (index) => {
    const updatedOptions = options.filter((_, idx) => idx !== index);
    setOptions(updatedOptions);
  };

  // Handle field change for inline editing
  const handleFieldChange = (e, field) => {
    setEditingField({ ...editingField, [field]: e.target.value });
  };

  // Group custom fields by collectionName
  const groupedFields = customFields.reduce((acc, field) => {
    if (!acc[field.collectionName]) {
      acc[field.collectionName] = [];
    }
    acc[field.collectionName].push(field);
    return acc;
  }, {});


  const handleDragEnd = async (event) => {
    const { active, over } = event;
  
    if (!over) return;
  
    const creationFields = customFields.filter((field) => field.useForCreation);
  
    const activeIndex = creationFields.findIndex((field) => field._id === active.id);
    const overIndex = creationFields.findIndex((field) => field._id === over.id);
  
    if (activeIndex !== overIndex) {
      // Reorder fields locally
      const reorderedFields = arrayMove(creationFields, activeIndex, overIndex);
  
      // Update local state
      const updatedCustomFields = customFields.map((field) => {
        const reorderedField = reorderedFields.find((f) => f._id === field._id);
        return reorderedField
          ? { ...reorderedField, orderId: reorderedFields.indexOf(reorderedField) + 1 }
          : field;
      });
  
      setCustomFields(updatedCustomFields);
      setUnsavedChanges(true);
  
      // Send reorder to the backend
      try {
        const payload = reorderedFields.map((field, index) => ({
          _id: field._id,
          orderId: index + 1,
        }));
  
        await reorderCustomFields(payload);
        console.log('Order updated successfully');
      } catch (error) {
        console.error('Error saving field order:', error);
      }
    }
  };
  
  
  
  const handleSaveOrder = async () => {
    const creationFields = customFields.filter((field) => field.useForCreation);
    
    try {
      await Promise.all(
        creationFields.map((field, index) =>
          updateCustomField(field._id, { orderId: index + 1 })
        )
      );
      alert('Order saved successfully');
      setUnsavedChanges(false); // Reset unsaved changes state
    } catch (err) {
      console.error('Error saving field order:', err);
      alert('Failed to save order. Please try again.');
    }
  };
  
  
  

  const SortableItem = ({ field }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: field._id,
    });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      background: '#f9f9f9',
      marginBottom: '8px',
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        {field.label}
      </div>
    );
  };

  const handleOptionChangeInline = (index, field, value) => {
    const updatedOptions = [...editingField.options];
    updatedOptions[index][field] = value;
    setEditingField({ ...editingField, options: updatedOptions });
  };

  const handleAddOptionInline = () => {
    setEditingField({
      ...editingField,
      options: [...editingField.options, { label: '', value: '' }],
    });
  };
  
  const handleRemoveOptionInline = (index) => {
    const updatedOptions = editingField.options.filter((_, idx) => idx !== index);
    setEditingField({ ...editingField, options: updatedOptions });
  };
  
  return (
    <Container>
      <Row className='justify-content-md-center'>
        <Col md={10}>
          <h1>Custom Fields Management</h1>
          {/* Help / How-To Section */}
          <Card className='mb-4'>
            <Card.Body>
              <Card.Title>How Custom Fields Work</Card.Title>
              <Card.Text>
                Custom fields allow you to add extra data to your campaigns, messages, or designs. 
                These fields can be text fields, select menus, or checkboxes, depending on your requirements.
                The fields will be displayed dynamically at the creation of a Campaign, Message or Design/File.
              </Card.Text>
              <ul>
                <li><strong>Text Field:</strong> A free text input where users can enter any information.</li>
                <li><strong>Select Menu:</strong> A dropdown menu with predefined options to choose from.</li>
                <li><strong>Checkbox:</strong> A simple on/off (true/false) toggle.</li>
              </ul>
              <Alert variant="info">
                <strong>Tip:</strong> Use custom fields to collect additional data from users or to store important metadata for each campaign or message. For example, you can create fields like "Client" and have a select menu of clients, "Internal ID", to link to your CRM ID, or "Users".
              </Alert>
            </Card.Body>
          </Card>
          <Form onSubmit={handleSubmit}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Create a New Custom Field</Card.Title>

                <Row className="my-4">
                  <Col md={6}>
                    <Form.Group controlId="formCollectionName">
                      <Form.Label>Collection</Form.Label>
                      <Form.Control
                        as="select"
                        value={collectionName}
                        onChange={(e) => setCollectionName(e.target.value)}
                      >
                        <option value="Campaigns">Campaigns</option>
                        <option value="Messages">Messages</option>
                        <option value="Designs">Designs</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="formLabel">
                      <Form.Label>Field Label</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter field label"
                        value={label}
                        onChange={(e) => setLabel(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="my-4">
                  <Col md={6}>
                    <Form.Group controlId="formType">
                      <Form.Label>Field Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="text">Text</option>
                        <option value="select">Select Menu</option>
                        <option value="checkbox">Checkbox</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                  {collectionName === 'Campaigns' && (
                    <Col md={6}>
                      <Form.Group controlId="formUseForCreation">
                        <Form.Check
                          type="checkbox"
                          label="Use for Campaign Creation"
                          checked={editingField.useForCreation || false}
                          onChange={(e) =>
                            setEditingField({ ...editingField, useForCreation: e.target.checked })
                          }
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>

                {type === 'select' && (
                  <Card className="mb-3">
                    <Card.Body>
                      <Card.Title>Select Menu Options</Card.Title>

                      {options.map((option, index) => (
                        <Row key={index} className="mb-2">
                          <Col md={5}>
                            <Form.Group controlId={`optionLabel-${index}`}>
                              <Form.Label>Label</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter option label"
                                value={option.label}
                                onChange={(e) => handleOptionChange(index, 'label', e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={5}>
                            <Form.Group controlId={`optionValue-${index}`}>
                              <Form.Label>Value</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter option value"
                                value={option.value}
                                onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={2} className="d-flex align-items-end">
                            <Button
                              variant="danger"
                              onClick={() => handleRemoveOption(index)}
                              disabled={options.length === 1}
                            >
                              -
                            </Button>
                          </Col>
                        </Row>
                      ))}

                      <Button variant="success" onClick={handleAddOption}>
                        +
                      </Button>
                    </Card.Body>
                  </Card>
                )}

                <Button className="mt-3 p-2" variant="primary" type="submit">
                  Create Custom Field
                </Button>
              </Card.Body>
            </Card>
          </Form>


          {/* Render custom fields grouped by collection */}
          {['Campaigns', 'Messages', 'Designs'].map(collection => (
            <Card key={collection} className='mt-4'>
              <Card.Body>
                <Card.Title>{collection} Custom Fields</Card.Title>
                {groupedFields[collection] && groupedFields[collection].length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Label</th>
                        <th>Type</th>
                        <th>Use for Campaign Creation</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
  {groupedFields[collection].map((field) => (
    <tr key={field._id}>
      <td>
        {editingFieldId === field._id ? (
          <Form.Control
            type="text"
            value={editingField.label || ''}
            onChange={(e) => handleFieldChange(e, 'label')}
          />
        ) : (
          field.label
        )}
      </td>
      <td>
        {editingFieldId === field._id ? (
          <Form.Control
            as="select"
            value={editingField.type || 'text'}
            onChange={(e) => handleFieldChange(e, 'type')}
          >
            <option value="text">Text</option>
            <option value="select">Select Menu</option>
            <option value="checkbox">Checkbox</option>
          </Form.Control>
        ) : (
          field.type
        )}
      </td>
      <td>
        {editingFieldId === field._id ? (
          editingField.type === 'checkbox' ? (
            <Form.Check
              type="checkbox"
              checked={editingField.useForCreation || false}
              onChange={(e) =>
                setEditingField({ ...editingField, useForCreation: e.target.checked })
              }
            />
          ) : (
            field.useForCreation ? 'Yes' : 'No'
          )
        ) : (
          field.useForCreation ? 'Yes' : 'No'
        )}
      </td>
      <td>
        {editingFieldId === field._id ? (
          <>
            {editingField.type === 'select' && (
              <div>
                {/* Inline editing for select menu options */}
                {editingField.options?.map((option, index) => (
                  <Row key={index} className="mb-2">
                    <Col md={5}>
                      <Form.Control
                        type="text"
                        placeholder="Option Label"
                        value={option.label || ''}
                        onChange={(e) =>
                          handleOptionChangeInline(index, 'label', e.target.value)
                        }
                      />
                    </Col>
                    <Col md={5}>
                      <Form.Control
                        type="text"
                        placeholder="Option Value"
                        value={option.value || ''}
                        onChange={(e) =>
                          handleOptionChangeInline(index, 'value', e.target.value)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleRemoveOptionInline(index)}
                      >
                        -
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="mt-3">
                <Button variant="success" size="sm" onClick={handleAddOptionInline}>
                  + Add Option
                </Button>
                </div>
              </div>
            )}
            <div className="mt-3">
            <Button variant="primary" size="sm" onClick={handleUpdate}>
              Save
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setEditingFieldId(null)}
              style={{ marginLeft: '10px' }}
            >
              Cancel
            </Button></div>
          </>
        ) : (
          <>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => handleEdit(field._id)}
            >
              Edit
            </Button>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={() => handleDelete(field._id)}
              style={{ marginLeft: '10px' }}
            >
              Delete
            </Button>
          </>
        )}
      </td>
    </tr>
  ))}
</tbody>


                  </Table>
                ) : (
                  <p>No custom fields found for {collection}</p>
                )}
              </Card.Body>
            </Card>
          ))}
           {customFields.some((field) => field.useForCreation) && (
  <Card className="mt-4">
    <Card.Body>
      <Card.Title>Order of Campaign Creation Fields</Card.Title>
      <Card.Text>
        Drag the fields to select the order they are displayed in. The order will automatically save.
      </Card.Text>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={customFields
            .filter((field) => field.useForCreation)
            .map((field) => field._id)}
          strategy={verticalListSortingStrategy}
        >
          {customFields
            .filter((field) => field.useForCreation)
            .sort((a, b) => a.orderId - b.orderId)
            .map((field) => (
              <SortableItem key={field._id} field={field} />
            ))}
        </SortableContext>
      </DndContext>
      {/* {unsavedChanges && (
        <Button className="mt-3" variant="primary" onClick={handleSaveOrder}>
          Save Order
        </Button>
      )} */}
    </Card.Body>
  </Card>
)}

        </Col>
      </Row>
    </Container>
  );
};

export default CustomFieldsPage;
