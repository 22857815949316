import React, { useState, useEffect, useCallback } from 'react';
import { Button, Container, Row, Col, Form, Modal, Card } from 'react-bootstrap';
import { listProductTypesByUser, listProductTypesByCountry, listPapers,fetchCampaigns, listEnvelopesType, createCampaign, updateCampaign, postingCountry, getCampaignProductsByCampaignId, listServicesByCountry, fetchCustomFields } from '../services/api';
import { createCampaignProduct, updateCampaignProduct } from '../services/api';
import { log } from '../services/logger';
import styles from '../styles/CampaignWizard.module.css';

const CampaignWizard = ({ show, onHide, userId, editingCampaign }) => {
  const [step, setStep] = useState(1);
  const [productTypes, setProductTypes] = useState([]);
  const [papers, setPapers] = useState([]);
  const [envelopes, setEnvelopes] = useState([]);
  const [services, setServices] = useState([]);
  const [bespokeProducts, setBespokeProducts] = useState([]);
  const [userCountry, setUserCountry] = useState('');
  const [campaignProductId, setCampaignProductId] = useState(null); // State to store CampaignProduct ID
  const [formData, setFormData] = useState({
    campaignName: '',
    productType: '',
    bespokeProductTypeId: '',
    paperType: '',
    envelopeType: '',
    serviceType: ''
  });
  const [campaignList, setCampaignList] = useState([]);
  const [customFields, setCustomFields] = useState([]); // State to store custom fields
  const [customFieldValues, setCustomFieldValues] = useState({}); // State to store values for custom fields

  const fetchData = useCallback(async (country) => {
    try {
      const [types, papers, envelopes, services, campaignsResponse,beSpokeProductsByUser] = await Promise.all([
        listProductTypesByCountry(country),
        listPapers(),
        listEnvelopesType(),
        listServicesByCountry(country),
        fetchCampaigns(userId), // Ensure fetchCampaigns is returning a correct response
        listProductTypesByUser(userId)
      ]);
  
      setProductTypes(types);
      setPapers(papers);
      setEnvelopes(envelopes);
      setServices(services);
      setBespokeProducts(beSpokeProductsByUser);
      const campaigns = campaignsResponse.data;  // Access the data property from the API response
      setCampaignList(campaigns);  // Set the campaigns in the state
      
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, [userId]);
  

  useEffect(() => {
    const fetchAndSetUserCountry = async () => {
      try {
        const countryData = await postingCountry();
        const country = countryData.data.postingCountry;
        setUserCountry(country);
        log(country);
        await fetchData(country);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchAndSetUserCountry();
  }, [fetchData]);

  useEffect(() => {
    const fetchCampaignProducts = async (campaignId) => {
      try {
        const response = await getCampaignProductsByCampaignId(campaignId);
        if (response.length > 0) {
          const { _id, productTypeId, paperId, envelopeId, serviceId } = response[0];
          setCampaignProductId(_id); // Store the CampaignProduct ID
          setFormData(prevFormData => ({
            ...prevFormData,
            productType: productTypeId._id,
            paperType: paperId._id,
            envelopeType: envelopeId._id,
            serviceType: serviceId._id
          }));
        } else {
          setCampaignProductId(null); // No existing CampaignProduct
        }
      } catch (error) {
        console.error('Error fetching campaign products:', error);
      }
    };

    if (show) {
      if (editingCampaign) {
        setFormData({
          campaignName: editingCampaign.campaignName,
          productType: '',
          paperType: '',
          envelopeType: '',
          serviceType: ''
        });
        setStep(1);
        fetchCampaignProducts(editingCampaign._id);
      } else {
        setFormData({
          campaignName: '',
          productType: '',
          paperType: '',
          envelopeType: '',
          serviceType: ''
        });
        setStep(1);
        setCampaignProductId(null); // Reset the CampaignProduct ID
      }
    }
  }, [show, editingCampaign]);

  // Fetch custom fields when entering or editing a campaign
  useEffect(() => {
    if (formData.campaignName) {
      fetchCustomFields().then((response) => {
        // Filter custom fields to only show those for 'Campaigns'
        const campaignFields = response.data.filter(field => field.collectionName === 'Campaigns');
        setCustomFields(campaignFields);  // Set only the custom fields related to 'Campaigns'
      }).catch((error) => {
        console.error('Error fetching custom fields:', error);
      });
    }
  }, [formData.campaignName]);
  
  const handleNext = () => {
    // If a bespoke product is selected, skip to the final step
    if (step === 1 && formData.productTypeId) {
      const selectedBespokeProduct = bespokeProducts.find(product => product._id === formData.productTypeId);
  
      if (selectedBespokeProduct) {
        // Skip to the save step
        setStep(5);
        return;
      }
    }
  
    // Standard flow where we check if the user has selected each step's required option
    if ((step === 2 && !formData.productType) ||
        (step === 3 && !formData.paperType) ||
        (step === 4 && !formData.envelopeType) ||
        (step === 5 && !formData.serviceType)) {
      alert("Please select an item before proceeding to the next step.");
      return;
    }
    
    // Proceed to the next step
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  const handleInputChange = (e) => {
    const { name, value,type, checked } = e.target;
    //console.log(name, value);
    // Check if the field is "parentCampaignId", "campaignName", or other standard fields
    if (name === 'bespokeProductTypeId' || name === 'productTypeId' || name === 'parentCampaignId' || name === 'campaignName' || name === 'productType' || name === 'paperType' || name === 'envelopeType' || name === 'serviceType') {
      // Update formData for standard fields
      setFormData({
        ...formData,
        [name]: value
      });
    } else {
      // Otherwise, it's likely a custom field, so update customFieldValues
      setCustomFieldValues(prevValues => ({
        ...prevValues,
        [name]: type === 'checkbox' ? checked : value // Handle checkboxes (true/false) and other inputs like select menus or text
      }));
    }
  };
  

  // // Handle custom field value changes
  // const handleCustomFieldChange = (label, value) => {
  //   setCustomFieldValues({
  //     ...customFieldValues,
  //     [label]: value
  //   });
  // };

  const handleSave = async () => {
    try {
      //console.log("Form Data before saving:", formData);

      // Prepare campaign data
      const campaignData = {
        campaignName: formData.campaignName,
        parentCampaign: formData.parentCampaignId,
        createdBy: userId,
        customFields: Object.keys(customFieldValues).map(label => ({
          label,
          value: customFieldValues[label]
        })) // Include custom fields in the campaign data
      };
      
      let campaignId;
      if (editingCampaign) {
        const response = await updateCampaign(editingCampaign._id, campaignData);
        log('Campaign updated successfully');
        campaignId = editingCampaign._id;
      } else {
        const response = await createCampaign(campaignData);
        log('Campaign created successfully:', response.data);
        campaignId = response.data.savedCampaign._id;
      }
  
      // Determine if a bespoke or standard product was selected
      const isBespoke = !!formData.bespokeProductTypeId;
      
      const campaignProductData = {
        userId,
        campaignId: campaignId,
        productTypeId: isBespoke ? formData.bespokeProductTypeId : formData.productType,  // Use bespoke or standard product
        paperId: isBespoke ? '66bb2d26a7115c842eed22aa' : formData.paperType,  // Placeholder for bespoke
        envelopeId: isBespoke ? '66bb19b7a7115c842eed132c' : formData.envelopeType,  // Placeholder for bespoke
        serviceId: isBespoke ? '6710eb0a6da2f591c90f305e' : formData.serviceType // Placeholder for bespoke
      };
     
      if (editingCampaign && campaignProductId) {
        await updateCampaignProduct(campaignProductId, campaignProductData);
        log('Campaign product updated successfully');
      } else {
        await createCampaignProduct(campaignProductData);
        log('Campaign product created successfully');
      }
  
      //onHide(); // Close the modal
    } catch (error) {
      console.error('Error saving campaign or products:', error);
    }
      onHide();
  };
  

  const calculateStartingPrice = () => {
    const productType = productTypes.find(type => type._id === formData.productType);
    const paper = papers.find(paper => paper._id === formData.paperType);
    const envelope = envelopes.find(envelope => envelope._id === formData.envelopeType);
    const service = services.find(service => service._id === formData.serviceType);

    const totalPrice = (productType?.rrp || 0) + (paper?.rrp || 0) + (envelope?.rrp || 0) + (service?.cost || 0);
    return totalPrice.toFixed(2);
  };

  const renderOptions = (items, name, basePath, filterFn) => {
    const filteredItems = filterFn ? items.filter(filterFn) : items;
    return filteredItems.map((item) => (
      <Col md="4" key={item._id} className="d-flex align-items-stretch">
        <div className={`${styles.card} ${formData[name] === item._id ? styles.selected : ''}`}>
          {item.thumbnail && <img src={`${process.env.PUBLIC_URL}${basePath}${item.thumbnail}`} className={styles.cardImgTop} alt={item.name} />}
          <div className={styles.cardBody}>
            <h5 className={styles.cardTitle}>{item.name}</h5>
            <p className={styles.cardText}>{item.description}</p>
            {name === 'serviceType' && item.cost ? (
              <p>Cost +£{item.cost}</p>
            ) : (
              item.rrp ? (
                <p>£{item.rrp}</p>
              ) : null
            )}
            <input
              type="radio"
              className="btn-check"
              id={`${name}-${item._id}`}
              autoComplete="off"
              checked={formData[name] === item._id}
              onChange={() => handleInputChange({ target: { name, value: item._id } })}
            />
            <label
              className={`btn btn-outline-primary ${formData[name] === item._id ? 'selected' : ''}`}
              htmlFor={`${name}-${item._id}`}
            >
              Select
            </label>
          </div>
        </div>
      </Col>
    ));
  };

  const filteredPapers = papers.filter(paper =>
    paper.productTypes.some(pt => pt === formData.productType)
  );

  const filteredEnvelopes = envelopes.filter(envelope =>
    envelope.productTypes.some(pt => pt === formData.productType)
  );

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header className={`d-flex justify-content-between align-items-center ${styles.modalHeader}`} closeButton>
        <Modal.Title className="flex-grow-1">Create Campaign</Modal.Title>
        <div>
          {/* If bespoke product is selected, show only the Save Campaign button */}
          {bespokeProducts.some(product => product._id === formData.bespokeProductTypeId) ? (
            <Button onClick={handleSave} className={styles.buttonSpacing}>Save Campaign</Button>
          ) : (
            <>
              {step > 1 && <Button onClick={handleBack} className={styles.buttonSpacing}>Back</Button>}
              {step < 5 && <Button onClick={handleNext} className={styles.buttonSpacing}>Next</Button>}
              {step === 5 && <Button onClick={handleSave} className={styles.buttonSpacing}>Save Campaign</Button>}
            </>
          )}
        </div>


      </Modal.Header>
      <Modal.Body className={styles.modalScrollable}>
        <Container>
          {step === 1 && (
            <Row className="justify-content-md-center align-items-center" style={{ minHeight: '50vh' }}>
            <Col md="8">
              <Form.Group controlId="campaignName" className="text-center">
                <Form.Label style={{ fontWeight: 'bold', fontSize: '2rem', marginBottom: '1rem' }}>
                  What would you like to name your campaign?
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter campaign name"
                  name="campaignName"
                  value={formData.campaignName}
                  onChange={handleInputChange}
                  style={{ fontSize: '1.2rem', padding: '1rem' }} // Larger input field
                />
              </Form.Group>
          
              <Form.Group controlId="parentCampaign">
                <Form.Label style={{ fontWeight: 'bold', fontSize: '1.5rem', margin: '1rem' }}>
                  Do you want to link this campaign to another? Select the parent campaign below:
                </Form.Label>
                <Form.Label style={{ fontSize: '1rem', margin: '1rem' }}>
                  Any campaigns already linked will not be available.
                </Form.Label>
                <Form.Select
                  name="parentCampaignId"
                  value={formData.parentCampaignId || ''}  // Default empty if no parent campaign selected
                  onChange={(e) => handleInputChange(e)}  // Handle the input change event
                >
                  <option value="">Select Parent Campaign</option>  {/* Placeholder option */}
                  {campaignList
                  .filter((campaign) => !campaign.parentCampaign)  // Filter out campaigns with a parentCampaign
                  .map((campaign) => (
                      <option key={campaign._id} value={campaign._id}>
                          {campaign.campaignName}
                      </option>
                  ))}
                </Form.Select>
                {bespokeProducts.length > 0 && (
                  <>
                    <Form.Label style={{ fontSize: '1rem', margin: '1rem' }}>
                      Choose Your Product Type
                    </Form.Label>
                    <Form.Select
                      name="bespokeProductTypeId"  // Use bespokeProductTypeId consistently
                      value={formData.bespokeProductTypeId || ''}  // Bind to bespokeProductTypeId in formData
                      onChange={(e) => handleInputChange(e)}  // Handle the input change event
                    >
                      <option value="">Select Bespoke Product Type</option>  {/* Placeholder option */}
                      {bespokeProducts.map((product) => (
                        <option key={product._id} value={product._id}>
                          {product.name}
                        </option>
                      ))}
                    </Form.Select>
                  </>
                )}

              </Form.Group>
          
              {/* Custom Fields Section inside a Card */}
              <Card className="mt-4">
              <Card.Header>
                <h5 style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Custom Fields</h5>
              </Card.Header>
              <Card.Body>
                {/* Render custom fields dynamically */}
                {customFields.length > 0 ? (
                  customFields.map(field => (
                    <Form.Group controlId={`customField-${field._id}`} key={field._id} className="mb-3">
                      <Form.Label>{field.label}</Form.Label>
                      {field.type === 'text' && (
                        <Form.Control
                          type="text"
                          name={field.label} // Use field label as the name to be handled by handleInputChange
                          value={customFieldValues[field.label] || ''}
                          onChange={handleInputChange}  // Directly use handleInputChange
                        />
                      )}
                      {field.type === 'select' && (
                        <Form.Control
                          as="select"
                          name={field.label} // Use field label as the name to be handled by handleInputChange
                          value={customFieldValues[field.label] || ''}
                          onChange={handleInputChange}  // Directly use handleInputChange
                        >
                          <option value="">Select an option</option>
                          {field.options.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Control>
                      )}
                      {field.type === 'checkbox' && (
                        <Form.Check
                          type="checkbox"
                          name={field.label} // Use field label as the name to be handled by handleInputChange
                          label={field.label}
                          checked={customFieldValues[field.label] || false}
                          onChange={handleInputChange}  // Directly use handleInputChange
                        />
                      )}
                    </Form.Group>
                  ))
                ) : (
                  <p>No custom fields available</p>
                )}
              </Card.Body>
            </Card>

            </Col>
          </Row>
          
          )}
          {/* Only render steps 2 through 5 if a bespoke product isn't selected */}
          {!bespokeProducts.some(product => product._id === formData.productTypeId) && (
            <>
              {(step === 2 || step === 3 || step === 4 || step === 5) && (
                <Row className="justify-content-md-center">
                  <Col md="12" className="text-center mb-3">
                    <h5>Starting at £{calculateStartingPrice()} {formData.serviceType ? '' : '(excluding postage)'}</h5>
                  </Col>
                </Row>
              )}

              {step === 2 && (
                <Row>
                  {renderOptions(productTypes, 'productType', '/assets/paper/')} {/* Hides cost for productType */}
                </Row>
              )}

              {step === 3 && (
                <Row>
                  {renderOptions(papers, 'paperType', '/assets/paper/', paper => filteredPapers.includes(paper))}
                </Row>
              )}

              {step === 4 && (
                <Row>
                  {renderOptions(envelopes, 'envelopeType', '/assets/paper/', envelope => filteredEnvelopes.includes(envelope))}
                </Row>
              )}

              {step === 5 && (
                <Row>
                  {renderOptions(services, 'serviceType', '/assets/paper/')}
                </Row>
              )}
            </>
          )}

        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CampaignWizard;
